import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import Chat from "./Chat";
import Landing from "./Landing";
import NavMenu from "./NavMenu";
import AccessTokenContext from "./AccessTokenContext";
import { getAuthConfig } from "../config";
const config = getAuthConfig();
export default function Body() {
	const { isLoading, user, isAuthenticated, getAccessTokenSilently } = useAuth0();
	const [accessToken, setAccessToken] = useState("");

	useEffect(() => {
		async function fetchData() {
			try {
				const accessToken = await getAccessTokenSilently({
					authorizationParams: {
						audience: config.audience,
						scope: config.scope,
					},
				});

				setAccessToken(accessToken);
			} catch (e) {
				console.log(e);
			}
		}
		if (isAuthenticated) {
			fetchData();
		}
	}, [getAccessTokenSilently, isAuthenticated]);

	return (
		<body>
			<NavMenu />
			{!isAuthenticated ? (
				<Landing />
			) : (
				<AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
					<Chat />
				</AccessTokenContext.Provider>
			)}
		</body>
	);
}

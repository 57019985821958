export default function ChatMessage({ messages }) {
	return (
		<div>
			{messages.map((message) => {
				return (
					<>
						<article aria-busy={message.user === ""}>{message.user}</article>
						<article aria-busy={message.agent === ""}>{message.agent}</article>
					</>
				);
			})}
		</div>
	);
}

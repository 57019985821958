import axios from "axios";
import { getAppConfig } from "../config";

const config = getAppConfig();

const url = config.api_url;

const postMessage = async (message, accessToken) => {
	const data = {
		message: message,
		agent_type: "User",
	};

	const response = await axios.post(url + "/chats", data, {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Access-Control-Allow-Origin": "*",
		},
	});
	return response.data;
};

const postUser = async (user) => {
	const data = {
		...user,
	};
	console.log(data);
	const response = await axios.post(url + "/users", data, {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	});
	return response.data;
};

export { postMessage, postUser };

import Login from "./Login";
import Logout from "./Logout";
import { useAuth0 } from '@auth0/auth0-react';
export default function NavMenu() {
    const {isAuthenticated} = useAuth0()

    return (
        <nav class="container-fluid">
            <ul>
                <li>
                    <strong>ChatInvest</strong>
                </li>
            </ul>
            <ul>
                <li>{!isAuthenticated ? <Login /> : <Logout /> }</li>
            </ul>
        </nav>
    )
}
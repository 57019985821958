import "@picocss/pico";
import "./style/theme.css";
// import {useState, useEffect} from 'react'
import { Auth0Provider } from "@auth0/auth0-react";
import { getAuthConfig } from "./config";
import Body from "./components/Body";

const config = getAuthConfig();

function App() {
	return (
		<Auth0Provider
			domain={config.domain}
			clientId={config.clientId}
			authorizationParams={{
				redirect_uri: window.location.origin,
				audience: config.audience,
				scope: config.scope,
			}}
		>
			<Body />
		</Auth0Provider>
	);
}

export default App;

import { useState } from "react";

export default function Chat({ onSubmit }) {
	const [message, setMessage] = useState("");

	function handleSubmit(event) {
		event.preventDefault();
		onSubmit(message);
		setMessage("");
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div>
					<textarea name="chatInput" value={message} onChange={(e) => setMessage(e.target.value)} />
				</div>
				<div>
					<button type="submit">Enviar</button>
				</div>
			</form>
		</>
	);
}

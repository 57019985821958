import configAuthJson from "./auth_config.json";
import appConfigJson from "./app_config.json";

export function getAuthConfig() {
	// Configure the audience here. By default, it will take whatever is in the config
	// (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
	// is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
	// don't have an API).
	// If this resolves to `null`, the API page changes to show some helpful info about what to do
	// with the audience.
	const audience = configAuthJson.audience && configAuthJson.audience !== "YOUR_API_IDENTIFIER" ? configAuthJson.audience : null;

	return {
		domain: configAuthJson.domain,
		clientId: configAuthJson.clientId,
		...(audience ? { audience } : null),
		scope: configAuthJson.scope,
	};
}

export function getAppConfig() {
	return {
		api_url: appConfigJson.api_url,
	};
}

import { postUser } from "../apis/serviceAPI";
import { useState } from "react";

export default function Landing() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	function handleSubmit(event) {
		event.preventDefault();
		const user = {
			name,
			email,
		};
		postUser(user)
			.then((response) => {
				setMessage("Agradecemos o interesse, fique atento na sua caixa de entrada. Você receberá o convite por email");
			})
			.catch((error) => {
				console.log(error);
				setMessage("Ocorreu um erro no envio do seu cadastro.");
			})
			.finally(() => {
				setName("");
				setEmail("");
			});
	}
	return (
		<>
			<header class="container">
				<hgroup>
					<h1>
						Um novo jeito de avaliar ações.
						<br /> Fácil, como uma simples conversa.
					</h1>
					<p>
						Processamos bases contendo diversas variáveis financeiras do mercado com o poder da Inteligência Artificial Generativa <br />
						para oferecer uma nova jornada para consultas e análises de ações brasileiras.
					</p>
				</hgroup>
			</header>
			<main class="container">
				<article>
					<hgroup>
						<h3>Inscreva-se</h3>
						<h4>Para receber um convite de acesso a nossa plataforma.</h4>
					</hgroup>
					<form onSubmit={handleSubmit}>
						<input type="text" name="name" placeholder="Nome" value={name} onChange={(event) => setName(event.target.value)} required />
						<input type="text" name="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} required />
						<button type="submit">Enviar</button>
					</form>
					<div>
						<span>{message}</span>
					</div>
				</article>
			</main>
			<footer class="container">
				<p>Dúvidas? Entre em contato conosco chat@chatinvest.com.br</p>
				<small>
					O chatinvest não tem o objetivo de fazer sugestão de compra ou venda de ativos, sendo assim, não se responsabiliza pelas decisões tomadas com base na análise das informações aqui
					apresentadas. Todas as informações apresentadas tem caráter informativo e são provenientes de fontes públicas como B3 e CVM. Utilizamos modelos preditivos que talvez produzam informações
					imprecisas.
				</small>
			</footer>
		</>
	);
}

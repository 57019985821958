import ChatMessage from "./ChatMessage";
import ChatInput from "./ChatInput";
import { useState, useContext } from "react";
import AccessTokenContext from "./AccessTokenContext";
import { postMessage } from "../apis/serviceAPI";

export default function Chat() {
	const [messages, setMessages] = useState([]);
	const [prefilled, setPrefilled] = useState("");
	const { accessToken, setAccessToken } = useContext(AccessTokenContext);

	const handleNewMessage = (text) => {
		let message = { user: text, agent: "" };
		setMessages([...messages, message]);
		postMessage(text, accessToken)
			.then((messageResponse) => {
				message.agent = messageResponse.message;
				setMessages([...messages, message]);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<>
			<main class="container">
				<section>
					<h3>Alguns exemplos de prompts</h3>
				</section>
				<section>
					<div class="grid">
						<div>
							<p>Baseando-se na estratégia de warren buffet de investimento, quais são as 5 melhores ações</p>
						</div>
						<div>
							<p>Liste as 10 ações com menor VPA</p>
						</div>
						<div>
							<p>O lucro liquido da Ambev vem crescendo nos últimos anos?</p>
						</div>
					</div>
				</section>
				<section id="chatlines">
					<div>
						<ChatMessage messages={messages} />
					</div>
				</section>
			</main>
			<footer class="container">
				<ChatInput onSubmit={handleNewMessage} prefilled={prefilled} />
			</footer>
		</>
	);
}
